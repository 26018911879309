import React, { Suspense, lazy, useState, useMemo } from 'react'
import { Switch, Redirect, Route } from 'react-router-dom'
import { UserContext } from './hook/UserContext'
import PublicRoute from './hook/publicRoute'
import PrivateRoute from './hook/PrivateRoute'

import Loader from '../app/shared/Loader'
import BloodComponent from './donor-inventory/BloodComponent'
import TenantDashboard from './tenant/dashboard'
import BloodStockDetail from './donor-inventory/DetailPage'
// import AddUser from './users/AddUser';

const PublicCertificate = lazy(() => import('./certificate/PublicCertificate'))
const RolesPage = lazy(() => import('./roles/Roles'))
const Certification = lazy(() => import('./certification'))
const EditCertificate = lazy(() => import('./certification/EditCertificate'))

const TenantPage = lazy(() => import('./tenants/Tenants'))
const AddTenantPage = lazy(() => import('./tenants/Add'))
const EditTenantPage = lazy(() => import('./tenants/Edit'))

const TenantNewPage = lazy(() => import('./tenantNew/TenantNew'))
const AddTenantsNewPage = lazy(() => import('./tenantNew/AddTenants'))
const TenantLoginPage = lazy(() => import('./tenantNew/TenantLogin'))

const UsersPage = lazy(() => import('./users/Users'))
const AddUserPage = lazy(() => import('./users/Add'))
const EditUserPage = lazy(() => import('./users/Edit'))

const EntityPage = lazy(() => import('./entity/Entity'))

const BloodPage = lazy(() => import('./blood/Blood'))
const BloodAvailabilityPage = lazy(() => import('./blood-search'))

const DonorsPage = lazy(() => import('./donors/Donors'))
const DonorAddPage2 = lazy(() => import('./abha/Abha'))
const PublicQrBagDetails = lazy(() => import('./public/BloodBagDetails'))
const NewBagDetails = lazy(() => import('./public/NewBagDetails'))
const CrossmatchReport = lazy(() => import('./public/crossmatch-report'))
const ScreeningTestNew = lazy(() => import('./screening'))
const DonorProfile = lazy(() => import('./donors/profile/DonorProfile'))
const BloodInventory = lazy(() => import('./inventory'))
const BloodStock = lazy(() => import('./blood-stock'))
const SettingsPage = lazy(() => import('./settings'))
const DonorInventory = lazy(() => import('./donor-inventory'))
const TTITesting = lazy(() => import('./donors/TTI'))
const TTIMain = lazy(() => import('./TTI'))
const BloodGroup = lazy(() => import('./blood-group'))
const BloodRequisition = lazy(() => import('./blood-requisition'))
const AddBloodRequisition = lazy(() => import('./blood-requisition/AddBloodRequest'))
const AddPlasmaRequisition = lazy(() => import('./blood-requisition/plasma-add'))
const PlasmaView = lazy(() => import('./blood-requisition/view/PlasmaView'))
const PlasmaRequests = lazy(() => import('./blood-requisition/plasma'))
const BloodBankRequest = lazy(() => import('./blood-loan'))
const AddLoanRequest = lazy(() => import('./blood-loan/AddLoanRequest'))
const EditBloodLoanRequest = lazy(() => import('./blood-loan/EditBloodLoanRequest'))
const AddBagsAfterRecieve = lazy(() => import('./blood-loan/recieved-view/RecievedIssue'))
const AllotBulkTransfer = lazy(() => import('./blood-loan/transfer-allotment'))
const Allotment = lazy(() => import('./blood-loan/allotment'))
const RequestLoanDetails = lazy(() => import('./blood-loan/request-view/RequestLoanDetails'))
const RecievedLoanDetails = lazy(() => import('./blood-loan/recieved-view'))
const RequestLogView = lazy(() => import('./blood-loan/log-view/RequestLogView'))
const RecievedAddComponents = lazy(() => import('./blood-loan/recieved-view/RecievedIssue'))

const ViewRequest = lazy(() => import('./blood-requisition/view/ViewRequest'))
const PaediatricView = lazy(() => import('./blood-requisition/view/PaediatricView'))
const BloodGrouping = lazy(() => import('./blood-requisition/blood-grouping/BloodGrouping'))
const BagAssignment = lazy(() => import('./blood-requisition/plasma/BagAssignment'))
const Ffpinvoice = lazy(() => import('./billing-ffp/Invoice/Ffpinvoice'))
const Payment = lazy(() => import('./blood-requisition/payment/Payment'))
const Invoice = lazy(() => import('./blood-requisition/payment/Invoice'))
const BillingReportComp = lazy(() => import('./billing/BillReportDownload'))
const IssueBloodBag = lazy(() => import('./blood-requisition/issuebloodbag'))
const PlasmaAssign = lazy(() => import('./blood-requisition/plasma/assign-plasma'))
const Reports = lazy(() => import('./reports'))
const CustomReports = lazy(() => import('./custom-registers'))
const Report = lazy(() => import('./reports/components/Report'))
const SupportSytem = lazy(() => import('./SupportSytem'))

const BloodCampManagement = lazy(() => import('./blood-camp-management'))
const AddBloodCamp = lazy(() => import('./blood-camp-management/addBloodCamp/index'))
const CampStaff = lazy(() => import('./blood-camp-management/addstaff'))
const AddStaff = lazy(() => import('./blood-camp-management/addstaff/AddStaff'))
const CampItems = lazy(() => import('./blood-camp-management/addItems'))
const AddItems = lazy(() => import('./blood-camp-management/addItems/AddItems'))

const FacilityInspection = lazy(() => import('./blood-camp-management/addfacilty'))
const Facility = lazy(() => import('./blood-camp-management/addfacilty/Facility'))
const ViewCamp = lazy(() => import('./blood-camp-management/viewcamp/ViewCamp'))
const PostCampReport = lazy(() => import('./blood-camp-management/postreport/index'))

const CrossMatchTesting = lazy(() => import('./CrossMatchTesting'))
const WasteManage = lazy(() => import('./WasteManage'))
const AutoClave = lazy(() => import('./WasteManage/AutoClave'))
const AddDonorInventory = lazy(() => import('./donor-inventory/AddInventory'))
const SuccessFailurePage = lazy(() => import('./donors/screening/SuccessFailure'))
const DonorDetails = lazy(() => import('./donors/details/Details'))
const UnderConstruction = lazy(() => import('./under-construction'))
const EHRDashboard = lazy(() => import('./dashboard/EHRDashboard'))
const EHRDashboardNew = lazy(() => import('./dashboard/DashboardNew'))
const BloodBankManage = lazy(() => import('./blood-bank-management/BloodBankManage'))

const Billing = lazy(() => import('./billing'))
const Barcodes = lazy(() => import('./barcodes'))
const MultipleBarCodes = lazy(() => import('./barcodes/multiple-barcode'))
const MultiplwBarcodes = lazy(() => import('./barcodes/bulk-barcode'))
const ConsumableStocks = lazy(() => import('./stocks'))

const FFPPayment = lazy(() => import('./billing-ffp/addNewBilling'))
const BarcodeGenerator = lazy(() => import('./print-pages/barcode'))
const ReportsPrint = lazy(() => import('./print-pages/reports'))
const InvoicePrint = lazy(() => import('./print-pages/invoice'))
const CampReport = lazy(() => import('./print-pages/pre-camp'))
const IssueBloodBagPrint = lazy(() => import('./print-pages/issue-blood-bag'))

const CrossMatching = lazy(() => import('./crossMatching'))
const PaediatricCrossMatching = lazy(() => import('./crossMatching/PaediatricCrossMatching'))

const IssueCertification = lazy(() => import('./issue-certification'))
const Terms = lazy(() => import('./terms'))
const Privacy = lazy(() => import('./privacy'))

// const DonorProfile = lazy(()=>import('./donors/profile/DonorProfile'))
const MasterRegister = lazy(() => import('./master-register'))

const Login = lazy(() => import('./user-pages/Login'))
const ResetPass = lazy(() => import('./user-pages/ResetPass'))
const ChangePass = lazy(() => import('./user-pages/ChangePass'))

const PdfMain = lazy(() => import('./GeneratePdf'))
const TtiReport = lazy(() => import('./TTI/TtiReport'))
const ReGroupingPaediatric = lazy(() => import('./blood-requisition/blood-grouping/ReGroupingPaediatric'))
const RequestNewBorn = lazy(() => import('./blood-requisition/new-request/RequestNewBorn'))
const AdminSettings = lazy(() => import('./admin-settings'))
const ImportClients = lazy(() => import('./admin-settings/ImportClients'))
const ActivityRecord = lazy(() => import('./activityrecord'))
const AdvanceFeatures = lazy(() => import('./advance-features'))
const AdvanceFeaturesDetail = lazy(() => import('./advance-features/detail-page'))
const BulkInventory = lazy(() => import('./bulk-inventory'))
const SMSLogs = lazy(() => import('./sms-logs'))

const GlobalSearchPage = lazy(() => import('../layout/shared/global-search/search-page'))

const AppRoutes = () => {
  const [user, setUser] = useState(null)

  const value = useMemo(() => ({ user, setUser }), [user, setUser])

  return (
    <Suspense fallback={<Loader isLoading={true} />}>
      <UserContext.Provider value={value}>
        <Switch>
          <Route exact path='/guest/terms' component={Terms} />
          <Route exact path='/guest/privacy' component={Privacy} />
          <PrivateRoute restricted={true} exact path='/' component={EHRDashboardNew} />
          <PrivateRoute restricted={true} exact path='/dashboard' component={EHRDashboardNew} />
          <PrivateRoute restricted={true} exact path='/tenant/dashboard' component={EHRDashboardNew} />
          <PrivateRoute restricted={true} exact path='/tenant/dashboard/:id' component={TenantDashboard} />

          <PrivateRoute restricted={true} exact path='/search' component={GlobalSearchPage} />

          <PrivateRoute restricted={true} exact path='/entity' component={EntityPage} />

          <PrivateRoute restricted={true} exact path='/tenants' component={TenantPage} />
          <PrivateRoute restricted={true} exact path='/tenants/add' component={AddTenantPage} />
          <PrivateRoute restricted={true} exact path='/tenants/:id' component={EditTenantPage} />
          <PrivateRoute restricted={true} exact path='/tenants-new' component={TenantNewPage} />
          <PrivateRoute restricted={true} exact path='/tenants-new/add' component={AddTenantsNewPage} />
          <PrivateRoute restricted={true} exact path='/tenants-new/login' component={TenantLoginPage} />

          <PrivateRoute restricted={true} exact path='/users' component={UsersPage} />
          <PrivateRoute restricted={true} exact path='/users/add' component={AddUserPage} />
          <PrivateRoute restricted={true} exact path='/users/:id' component={EditUserPage} />

          <PrivateRoute restricted={true} exact path='/roles' component={RolesPage} />

          <PrivateRoute restricted={true} exact path='/bloods' component={BloodAvailabilityPage} />
          <PrivateRoute restricted={true} exact path='/reports/:type?' component={Reports} />
          <PrivateRoute restricted={true} exact path='/custom-reports' component={CustomReports} />

          <PrivateRoute restricted={true} exact path='/reports/:id' component={Report} />

          <PrivateRoute restricted={true} exact path='/donors' component={DonorsPage} />
          <PrivateRoute restricted={true} exact path='/issue-certification' component={IssueCertification} />

          <PrivateRoute restricted={true} exact path='/donors-detail/screening/:id' component={ScreeningTestNew} />
          <PrivateRoute restricted={true} exact path='/donors-detail/testing/:id' component={TTITesting} />

          <PrivateRoute restricted={true} exact path='/donors/success-failure/:id' component={SuccessFailurePage} />

          <PrivateRoute restricted={true} exact path='/donors/add' component={DonorAddPage2} />

          <PrivateRoute restricted={true} exact path='/donors-detail/blood-inventory/:id' component={BloodInventory} />

          <PrivateRoute restricted={true} exact path='/donors-detail/donor-inventory/:id' component={DonorInventory} />

          <PrivateRoute
            restricted={true}
            exact
            path='/donors-detail/donor-inventory/add/:id'
            component={AddDonorInventory}
          />
          <PrivateRoute restricted={true} exact path='/donor-inventory/blood-issue' component={BloodComponent} />
          <PrivateRoute restricted={true} exact path='/serology/lab-testing' component={TTIMain} />
          <PrivateRoute restricted={true} exact path='/serology/blood-group-testing' component={BloodGroup} />
          <PrivateRoute restricted={true} exact path='/serology/blood-group-testing/:id' component={BloodGroup} />

          <PrivateRoute restricted={true} exact path='/billing' component={Billing} />

          <PrivateRoute restricted={true} exact path='/billing/ffp/payment' component={FFPPayment} />

          <PrivateRoute restricted={true} exact path='/billing/ffp/payment/new/:id' component={FFPPayment} />

          <PrivateRoute restricted={true} exact path='/barcodes' component={Barcodes} />
          <PrivateRoute restricted={true} exact path='/barcodes-multiple' component={MultipleBarCodes} />
          <PrivateRoute restricted={true} exact path='/barcodes/multiple' component={MultiplwBarcodes} />
          <PrivateRoute restricted={true} exact path='/blood-requisition' component={BloodRequisition} />
          <PrivateRoute restricted={true} exact path='/blood-requisition/add' component={AddBloodRequisition} />
          <PrivateRoute restricted={true} exact path='/blood-requisition/plasma/add' component={AddPlasmaRequisition} />
          <PrivateRoute restricted={true} exact path='/blood-requisition/plasma/view/:id' component={PlasmaView} />

          <PrivateRoute
            restricted={true}
            exact
            path='/blood-requisition/plasma/edit/:id'
            component={AddPlasmaRequisition}
          />

          <PrivateRoute restricted={true} exact path='/blood-loan/:type?' component={BloodBankRequest} />

          <PrivateRoute restricted={true} exact path='/blood-loan/request/:type?' component={AddLoanRequest} />
          <PrivateRoute restricted={true} exact path='/blood-loan/edit/:id/:type?' component={EditBloodLoanRequest} />
          <PrivateRoute restricted={true} exact path='/blood-loan/recieve/add/:id' component={AddBagsAfterRecieve} />
          <PrivateRoute restricted={true} exact path='/blood-loan/transfer/request/:id/:type?' component={AllotBulkTransfer} />
          <PrivateRoute restricted={true} exact path='/blood-loan/transfer/request-allotment' component={Allotment} />

          <PrivateRoute
            restricted={true}
            exact
            path='/blood-loan/request-view/:id/:requestid'
            component={RequestLoanDetails}
          />
          <PrivateRoute
            restricted={true}
            exact
            path='/blood-loan/recieved-view/:id/:requestid'
            component={RecievedLoanDetails}
          />
          <PrivateRoute
            restricted={true}
            exact
            path='/blood-loan/recieved-add/:id/:requestid?'
            component={RecievedAddComponents}
          />
          <PrivateRoute
            restricted={true}
            exact
            path='/blood-loan/recieved-view/:id/:requestid'
            component={RecievedLoanDetails}
          />

          <PrivateRoute restricted={true} exact path='/blood-loan/view-details/:id/:type?' component={RequestLogView} />

          <PrivateRoute restricted={true} exact path='/blood-requisition/issuebloodbag/:id' component={IssueBloodBag} />
          <PrivateRoute restricted={true} exact path='/blood-requisition/edit/:id' component={AddBloodRequisition} />
          <PrivateRoute restricted={true} exact path='/blood-requisition/view/:id' component={ViewRequest} />
          <PrivateRoute
            restricted={true}
            exact
            path='/blood-requisition/paediatric/view/:id'
            component={PaediatricView}
          />
          <PrivateRoute
            restricted={true}
            exact
            path='/blood-requisition/plasma/add-bags/:id'
            component={BagAssignment}
          />
          <PrivateRoute restricted={true} exact path='/blood-requisition/plasma/:id' component={PlasmaAssign} />
          <PrivateRoute restricted={true} exact path='/blood-grouping/:id' component={BloodGrouping} />

          <PrivateRoute restricted={true} exact path='/cross-match-testing' component={CrossMatchTesting} />
          <PrivateRoute restricted={true} exact path='/waste-management/waste-list' component={WasteManage} />

          <PrivateRoute restricted={true} exact path='/waste-management/autoclave' component={AutoClave} />

          <PrivateRoute restricted={true} exact path='/blood-stock' component={BloodComponent} />
          <PrivateRoute restricted={true} exact path='/blood-stock/:id/:comp?' component={BloodStockDetail} />
          <PrivateRoute restricted={true} exact path='/donors-detail/:id' component={DonorProfile} />
          <PrivateRoute restricted={true} exact path='/donors/history/:id' component={DonorDetails} />
          <PrivateRoute restricted={true} exact path='/under-construction' component={UnderConstruction} />
          <PrivateRoute restricted={true} exact path='/blood-camp-management' component={BloodCampManagement} />
          <PrivateRoute restricted={true} exact path='/blood-camp-management/add' component={AddBloodCamp} />
          <PrivateRoute restricted={true} exact path='/blood-camp-management/view/:id/:tab?' component={ViewCamp} />
          <PrivateRoute restricted={true} exact path='/blood-camp-management/staff/:id?' component={CampStaff} />
          <PrivateRoute restricted={true} exact path='/blood-camp-management/items/:id?' component={CampItems} />
          <PrivateRoute restricted={true} exact path='/blood-camp-management/facility/add/:id?' component={Facility} />
          <PrivateRoute restricted={true} exact path='/blood-camp-management/staff/add/:id?' component={AddStaff} />
          <PrivateRoute restricted={true} exact path='/blood-camp-management/items/add/:id?' component={AddItems} />
          <PrivateRoute restricted={true} exact path='/blood-camp-management/view/:id' component={ViewCamp} />
          <PrivateRoute restricted={true} exact path='/blood-camp-management/staff' component={AddStaff} />
          <PrivateRoute restricted={true} exact path='/blood-camp-management/items' component={AddItems} />
          <PrivateRoute restricted={true} exact path='/blood-camp-management/facility' component={FacilityInspection} />
          <PrivateRoute restricted={true} exact path='/blood-camp-management/facility/:id' component={Facility} />
          <PrivateRoute
            restricted={true}
            exact
            path='/blood-camp-management/post-camp-report/:id?'
            component={PostCampReport}
          />
          <PrivateRoute restricted={true} exact path='/donors-detail/profile/:id' component={DonorProfile} />
          <PrivateRoute restricted={true} exact path='/manage-account/:query?' component={BloodBankManage} />

          <PrivateRoute restricted={true} exact path='/certification' component={Certification} />
          <PrivateRoute restricted={true} exact path='/ttireport/:id?' component={TtiReport} />
          <PrivateRoute
            restricted={true}
            exact
            path='/certification/edit/:id/:type/:campId?'
            component={EditCertificate}
          />
          <PrivateRoute restricted={true} exact path='/certification/:types/:id' component={Certification} />
          <PrivateRoute
            restricted={true}
            exact
            path='/blood-requisition/cross-matching/:id'
            component={CrossMatching}
          />
          <PrivateRoute
            restricted={true}
            exact
            path='/blood-requisition/paediatric/cross-matching/:id'
            component={PaediatricCrossMatching}
          />
          <PrivateRoute restricted={true} exact path='/blood-requisition/payment/:id' component={Payment} />

          <PrivateRoute
            restricted={true}
            exact
            path='/blood-requisition/paediatric/re-grouping/:id'
            component={ReGroupingPaediatric}
          />

          <PrivateRoute restricted={true} exact path='/blood-requisition/paediatric/add' component={RequestNewBorn} />

          <PrivateRoute
            restricted={true}
            exact
            path='/blood-requisition/paediatric/edit/:id'
            component={RequestNewBorn}
          />

          <PrivateRoute restricted={true} exact path='/billing/payment/:id' component={Payment} />
          <PrivateRoute restricted={true} exact path='/billing/payment' component={Payment} />

          <PrivateRoute restricted={true} exact path='/billing/payment/invoice/:id' component={Invoice} />

          <PrivateRoute restricted={true} exact path='/stock-items/:type?' component={ConsumableStocks} />

          <PrivateRoute restricted={true} exact path='/billing/payment/invoice/plasma/:id' component={Ffpinvoice} />

          <PrivateRoute restricted={true} exact path='/billing/report/invoice/:id' component={BillingReportComp} />

          <PrivateRoute restricted={true} exact path='/print-pages/barcode/:value?' component={BarcodeGenerator} />
          <PrivateRoute restricted={true} exact path='/print-pages/reports/:value?' component={ReportsPrint} />

          <PrivateRoute restricted={true} exact path='/print-pages/invoice/:value' component={InvoicePrint} />

          <PrivateRoute restricted={true} exact path='/print-pages/camp/:value?' component={CampReport} />
          <PrivateRoute restricted={true} exact path='/support' component={SupportSytem} />
          <PrivateRoute restricted={true} exact path='/advance-features' component={AdvanceFeatures} />
          <PrivateRoute restricted={true} exact path='/advance-features/:id' component={AdvanceFeaturesDetail} />
          <PrivateRoute restricted={true} exact path='/bulk-inventory' component={BulkInventory} />

          <PrivateRoute
            restricted={true}
            exact
            path='/print-pages/issue-blood-bag-details/:value?'
            component={IssueBloodBagPrint}
          />

          <PrivateRoute restricted={true} exact path='/master-register' component={MasterRegister} />
          <PrivateRoute restricted={true} exact path='/pdf' component={PdfMain} />
          <PrivateRoute restricted={true} exact path='/activityrecord' component={ActivityRecord} />
          <PrivateRoute restricted={true} exact path='/sms-logs' component={SMSLogs} />

          {/* settings new routes start */}

          <PrivateRoute restricted={true} exact path='/admin-settings' component={AdminSettings} />
          <PrivateRoute
            restricted={true}
            exact
            path='/admin-settings/blood-center/:query?'
            component={BloodBankManage}
          />
          <PrivateRoute restricted={true} exact path='/admin-settings/users' component={UsersPage} />
          <PrivateRoute restricted={true} exact path='/admin-settings/roles' component={RolesPage} />
          <PrivateRoute restricted={true} exact path='/admin-settings/qr-code' component={RolesPage} />
          <PrivateRoute restricted={true} exact path='/admin-settings/donor-form' component={RolesPage} />
          <PrivateRoute restricted={true} exact path='/admin-settings/import-client' component={ImportClients} />

          {/* settings new routes end */}



          {/* <PrivateRoute restricted={true} exact path='/activityrecord' component={ActivityRecord} /> */}

          <PublicRoute restricted={false} path='/login' component={<Login />} />
          <PublicRoute restricted={false} path='/reset-password/:token' component={<ResetPass />} />
          <PublicRoute restricted={false} path='/change-password' component={<ChangePass />} />
          {/* <PublicRoute
            restricted={false}
            path="/donor/registeration"
            component={<DonorAddPage2 self={true} />}
          /> */}
          <PublicRoute
            restricted={false}
            path='/donor/registeration/:donType?/:id?/:slug?'
            component={<DonorAddPage2 self={true} />}
          />
          <PublicRoute restricted={false} path='/guest/crossmatch-report' component={<CrossmatchReport />} />
          <PublicRoute restricted={false} path='/guest/bag-details' component={<NewBagDetails />} />
          <PublicRoute restricted={false} path='/guest/bag/:id/:type?' component={<PublicQrBagDetails />} />
          <PublicRoute
            restricted={false}
            path='/donors/camp-registeration/:donType?/:id?/:slug?'
            component={<DonorAddPage2 self={false} />}
          />
          <PublicRoute restricted={false} path='/print-pages/downloadfile' component={<PublicCertificate />} />
          <PublicRoute restricted={false} path='/p/df' component={<PublicCertificate />} />

          <Route path='/404'>
            <div className='div_ceneter'>
              <h1>404 Not Found</h1>
            </div>
          </Route>

          <Redirect to='/404' />
        </Switch>
      </UserContext.Provider>
    </Suspense>
  )
}

export default AppRoutes
