import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import AxiosHelper from '../../../helper/AxiosHelper'
import { toast } from 'react-toastify'
//import { makeStyles } from "@material-ui/core/styles";
//import { makeStyles } from '@mui/styles';
import { FormControl, FormHelperText, MenuItem, TextField, Select, Box, Alert } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { useAppContext } from '../../../AppContext'
import RHFTextField from '../../shared/RHFTextfield'
import { inputStyles } from '../../certification/EditCertificate'
import { Col, Form, Row } from 'react-bootstrap'
import MUIAutocomplete from '../../shared/MUIAutocomplete'
import { getInitials, getPinCodeData } from '../../middleware/auth'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { RHFDatePicker } from '../../shared/RHFDatePicker'
import { Trans } from 'react-i18next'
import FormProvider from '../../shared/FormProvider'
import { useHistory } from 'react-router-dom'
import useOnClickOutside from '../../hook/useClickOnOutside'
import { filterOptions, phoneRegExp } from '../../abha/components/DonorDetail'
import Loader from '../../shared/Loader'

export const bloodGroupArray = [
  { value: 'AB+Ve', label: 'AB+' },
  { value: 'AB-Ve', label: 'AB-' },
  { value: 'A+Ve', label: 'A+' },
  { value: 'A-Ve', label: 'A-' },
  { value: 'B+Ve', label: 'B+' },
  { value: 'B-Ve', label: 'B-' },
  { value: 'O-Ve', label: 'O-' },
  { value: 'O+Ve', label: 'O+' }
]

export const inputStylesSmall = {
  background: '#f5f5f5',
  border: '1px solid #d1d5db',
  borderRadius: '0.5rem',
  '& .MuiOutlinedInput-notchedOutline': {
    borderWidth: 0
  },
  '& .MuiInputBase-input': {
    height: 30
  },
  '& .MuiInputBase-root': {
    height: 45
  },
  '&:focus-within fieldset, &:focus-visible fieldset': {
    borderWidth: '1px!important',
    borderRadius: '0.5rem'
  }
}

export const inputSelectStyles = {
  background: '#f5f5f5',
  border: '1px solid #d1d5db',
  borderRadius: '0.5rem',
  height: '45px ',

  '& .MuiOutlinedInput-notchedOutline': {
    borderWidth: 0
  },

  '& .MuiInputBase-root': {
    height: 45
  },
  '&:focus-within fieldset, &:focus-visible fieldset': {
    borderWidth: '1px!important',
    borderRadius: '0.5rem'
  }
}

const defaultValues = {
  donation_type: 'Voluntary',
  martial_status: 'Prefer not to Say',
  relation: 'Father',
  gender: 'Male',
  state: '',
  district: '',
  name: '',
  father_name: '',
  dob: '',
  mobile: '',
  city: '',
  area: '',
  camp_id: ''
}

let LetterMessage = 'Must be less than 50 characters'

export const regex = /^(1[8-9])|([2-5][0-9])|(6[0-5])$/
export const emailregex = '^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$'

const letters = /^[a-zA-Z ]*$/
const schema = yup.object().shape({
  type: yup.string(),
  camp_id: yup.string().when('type', {
    is: 'camp',
    then: () => yup.string().required('Camp is required')
  }),
  father_name: yup.string().required('Father name is required').max(50, LetterMessage).matches(letters, 'Invalid name'),
  email: yup.string().optional().email(),
  age: yup.string().required('Age is required').matches(regex, 'Age Must be between 18 - 65 years'),
  name: yup.string().required('Name is required').matches(letters, 'Invalid name').max(50, LetterMessage),
  area: yup.string().max(50, LetterMessage),
  state: yup.string().required('state is required'),
  district: yup.string().required('district is required'),
  city: yup.string().required('city is required'),
  // reg_date: yup.string().required("Registration Date is required"),
  mobile: yup.string().required('Mobile number is required').matches(phoneRegExp, 'mobile number is not valid')
})

const EditProfile = props => {
  const { data, handleEdit, getDonorDetails, submitRef, detailPageData, setDetailPageData } = props
  const [error, setError] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [message, setMessage] = useState(null)
  const [focus, setFocus] = React.useState(false)
  const [registeredData, setRegisteredData] = React.useState([])
  const [hospitalinfo, sertHospitalInfo] = useState(false)

  const mobileref = useRef()

  const [states, setStates] = useState([])
  const [districts, setDistricts] = useState([])

  const methods = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(schema)
  })

  // ** Hook
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    setError: setForrmError,
    reset,
    setFocus: setErrorFocus,
    formState: { errors }
  } = methods

  React.useEffect(() => {
    const firstError = Object.keys(errors).reduce((field, a) => {
      return !!errors[field] ? field : a
    }, null)

    if (firstError) {
      setErrorFocus(firstError)
    }
  }, [errors, setErrorFocus])

  const getStates = () => {
    AxiosHelper.get('address/state')
      .then(response => {
        setStates(response?.data?.data)
        let arr = response?.data?.data
        return arr
      })
      .catch(error => {
        console.log(error)
      })
  }

  const getDistict = val => {
    AxiosHelper.get('address/district/' + val)
      .then(response => {
        setDistricts(response?.data?.data)
      })
      .catch(error => {
        console.log(error)
      })
  }

  useEffect(() => {
    getStates()
  }, [])

  useEffect(() => {
    if (watch('donation_type') === 'Voluntary') {
      sertHospitalInfo(false)
    } else {
      sertHospitalInfo(true)
    }
  }, [watch('donation_type')])

  useEffect(() => {
    if (watch('relation') === 'Husband' && watch('gender') === 'Female') {
      setValue('martial_status', 'Married')
      setValue('spouse_name', watch('father_name'))
    }
  }, [watch('relation'), watch('gender')])

  const handleSubmitForm = data => {
    setLoading(true)
    let bodyData = data
    delete bodyData['abha_id']
    if (data?.martial_status === 'Prefer not to Say') {
      delete bodyData['martial_status']
    }

    AxiosHelper.post('donor/basic-info', bodyData)
      .then(response => {
        setLoading(false)
        getDonorDetails()
        handleEdit()
      })
      .catch(error => {
        handlerError(error)
      })
  }

  const handleGetNumber = value => {
    if (value.length < 10) {
      setRegisteredData([])
      return
    }
    let url = 'donor/check-mobile'
    let data = {
      mobile: value
    }
    AxiosHelper.post(url, data)
      .then(response => {
        setRegisteredData(response?.data?.data)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const handlerError = error => {
    setLoading(false)
    setMessage(error)
    setError(true)
    // toast.error(error);
  }

  useEffect(() => {
    if (data) {
      data.dob = data?.dob ? dayjs(data?.dob) : ''
      data.martial_status = data?.martial_status ? data?.martial_status : 'Prefer not to Say'
      data.email = data?.email ? data?.email : ''
      reset({
        ...defaultValues,
        ...data
      })
    }
  }, [data])

  useOnClickOutside(mobileref, () => {
    setFocus(false)
  })

  useEffect(() => {
    if (watch('state')) {
      getDistict(watch('state'))
    }
  }, [watch('state')])

  useEffect(() => {
    if (watch('dob')) {
      let age = dayjs().diff(dayjs(watch('dob')), 'years')
      setValue('age', age)
    }
  }, [watch('dob')])

  useEffect(() => {
    if (states.length && detailPageData) {
      let getStateId = states?.find(ele => {
        return ele.name.toUpperCase() == detailPageData?.state?.toUpperCase()
      })
      setValue('state', getStateId?.name)
    }
  }, [states])

  async function getAddressVal(val) {
    if (val?.length === 6) {
      setLoading(true)
      let address = await getPinCodeData(val)
      if (address) {
        setValue('state', address?.State || '', {
          shouldValidate: true,
          shouldDirty: true
        })
        setValue('district', address?.District || '', {
          shouldValidate: true,
          shouldDirty: true
        })
        setValue('city', address?.Block || '', {
          shouldValidate: true,
          shouldDirty: true
        })
      }
      setLoading(false)
    }
  }

  useEffect(() => {
    getAddressVal(watch('pincode'))
  }, [watch('pincode')])
  //const classes = useStyles();
  return (
    <div className='profile-detail'>
      <Loader isLoading={isLoading} />
      <FormProvider methods={methods} onSubmit={handleSubmit(handleSubmitForm)}>
        <Row className='p-2 quick-donor-regestration-container'>
          {/* <section className="detail-section">
          <div className="detail-header">Basic Information</div>
          <div className="detail-grid">
            {basicInfo.map((item, idx) => {
              if (item["itemKey"] == "Blood Group") return;

              return (
                <div className="grid-item">
                  {item["itemKey"] !== "Date of Birth" &&
                    item["itemKey"] !== "Email Id" &&
                    item["itemKey"] !== "Gender" &&
                    item["itemKey"] !== "Spouse name" &&
                    item["itemKey"] !== "Marital Status" && (
                      <>
                        <p className="item-header">{item["itemKey"]}</p>
                        <TextField
                          size="small"
                          fullWidth
                          sx={inputStylesSmall}
                          onChange={handleBasicInfo}
                          type="text"
                          // className="form-control abha_input-num"
                          placeholder={`Enter ${item["itemKey"]}`}
                          name={item["name"]}
                          value={basicInfoState[item?.name]}
                        />
                      </>
                    )}
                  {item["itemKey"] === "Email Id" && (
                    <>
                      <p className="item-header">{item["itemKey"]}</p>
                      <TextField
                        fullWidth
                        size="small"
                        sx={inputStylesSmall}
                        onChange={handleBasicInfo}
                        type="text"
                        // className="form-control abha_input-num"
                        placeholder={`Enter ${item["itemKey"]}`}
                        name={item["email"]}
                        value={basicInfoState[item?.name]}
                      />
                    </>
                  )}
                  {item["itemKey"] === "Date of Birth" && (
                    <>
                      <p className="item-header">{item["itemKey"]}</p>

                      <FormControl fullWidth>
                        <DatePicker
                          format="DD/MM/YYYY"
                          // minDate={dayjs().subtract(65, "years")}
                          maxDate={dayjs().subtract(18, "years")}
                          slotProps={{
                            textField: { size: "small" },
                          }}
                          disableFuture
                          sx={inputStylesSmall}
                          value={basicInfoState?.dob}
                          onChange={(value) => {
                            setBasicInfoState({
                              ...basicInfoState,
                              dob: value,
                            });
                          }}
                          renderInput={(params) => (
                            <TextField fullWidth {...params} />
                          )}
                        />
                      </FormControl>
                    </>
                  )}


                  {item["itemKey"] === "Gender" && (
                    <>
                      <p className="item-header">{item["itemKey"]}</p>
                      <TextField
                        sx={inputStylesSmall}
                        size="small"
                        fullWidth
                        select
                        onChange={handleBasicInfo}
                        placeholder={`Enter ${item["itemKey"]}`}
                        name={item["name"]}
                        value={basicInfoState[item?.name]}
                      >
                        <MenuItem>---Select Gender---</MenuItem>
                        <MenuItem value="Male">Male</MenuItem>
                        <MenuItem value="Female">Female</MenuItem>
                      </TextField>
                    </>
                  )}

                  {item["itemKey"] === "Marital Status" && (
                    <>
                      <p className="item-header">{item["itemKey"]}</p>
                      <TextField
                        sx={inputStylesSmall}
                        size="small"
                        fullWidth
                        select
                        onChange={handleBasicInfo}
                        placeholder={`Enter ${item["itemKey"]}`}
                        name={item["name"]}
                        value={basicInfoState[item?.name]}
                      >
                        <MenuItem value="">--Martial Status--</MenuItem>
                        <MenuItem value="Married">Married</MenuItem>
                        <MenuItem value="Single">Single</MenuItem>
                      </TextField>
                    </>
                  )}
                  {item["itemKey"] === "Spouse name" &&
                    basicInfoState?.martial_status === "Married" && (
                      <>
                        <p className="item-header">{item["itemKey"]}</p>
                        <TextField
                          sx={inputStylesSmall}
                          fullWidth
                          onChange={handleBasicInfo}
                          type="text"
                          size="small"
                          // className="form-control abha_input-num"
                          placeholder={`Enter ${item["itemKey"]}`}
                          name={item["name"]}
                          value={basicInfoState["spouse_name"]}
                        />
                      </>
                    )}
                  {formErrors[item["name"]] && (
                    <FormHelperText sx={{ color: "error.main" }}>
                      {formErrors[item["name"]]}
                    </FormHelperText>
                  )}
                </div>
              );
            })}
          </div>
        </section> */}
          <Col xs={12} sm={6} md={4}>
            <Form.Group className='mb-3'>
              <Form.Label>
                Enter Your Name <span className='text-danger'>*</span>
              </Form.Label>

              <RHFTextField sx={inputStyles} fullWidth size='small' type='text' placeholder='Donor Name' name='name' />
            </Form.Group>
          </Col>

          <Col xs={12} sm={6} md={4}>
            <Form.Group className='mb-3'>
              <Form.Label>
                Father/Husband's Name <span className='text-danger'>*</span>
              </Form.Label>

              <RHFTextField
                sx={inputStyles}
                size='small'
                fullWidth
                type='text'
                placeholder='Father/Husband Name'
                name='father_name'
              />
            </Form.Group>
          </Col>

          <Col xs={12} sm={6} md={4}>
            <Form.Group className='mb-3'>
              <Form.Label>
                Relation <span className='text-danger'>*</span>
              </Form.Label>

              <ul className='donate-now'>
                <li>
                  <input
                    type='radio'
                    id='Father'
                    value='Father'
                    name='relation'
                    onChange={() => {
                      setValue('relation', 'Father')
                    }}
                    checked={watch('relation') === 'Father'}
                    // required
                  />
                  <label htmlFor='Father'>Father</label>
                </li>
                <li>
                  <input
                    type='radio'
                    id='Husband'
                    value='Husband'
                    name='relation'
                    checked={watch('relation') === 'Husband'}
                    onChange={() => {
                      setValue('relation', 'Husband')
                    }}
                  />
                  <label htmlFor='Husband'>Husband</label>
                </li>
              </ul>
            </Form.Group>
          </Col>

          {/* <Col xs={12} sm={6} md={4}>
            <Form.Group
              ref={mobileref}
              style={{ position: "relative" }}
              className="mb-3"
            >
              <Form.Label sx={{ marginBottom: "3px" }}>
                Mobile <span className="text-danger">*</span>
              </Form.Label>

              <TextField
                sx={inputStyles}
                fullWidth
                type="number"
                size="small"
                onFocus={() => setFocus(true)}
                onKeyDown={(e) => {
                  if (e.key === "Tab") {
                    setFocus(false);
                  }
                }}
                placeholder="Donor Mobile"
                name="mobile"
                onChange={(e) => {
                  setValue("mobile", e.target.value, {
                    shouldValidate: true,
                    shouldDirty: true,
                  });
                  handleGetNumber(e.target.value);
                }}
                value={watch("mobile")}
                InputProps={{
                  startAdornment: (
                    <Box sx={{ borderRight: "2px solid #d1d5db", mr: 1 }}>
                      <Select
                        size="small"
                        variant="standard"
                        disableUnderline
                        value="+91"
                      >
                        <MenuItem value="+91">+91</MenuItem>
                      </Select>
                    </Box>
                  ),
                }}
              />
              {errors?.mobile ? (
                <FormHelperText sx={{ color: "error.main" }}>
                  {errors?.mobile?.message}
                </FormHelperText>
              ) : null}

              {focus ? (
                <div
                  style={{ position: "absolute" }}
                  className="searchCustomm "
                >
                  {registeredData?.length > 0 ? (
                    registeredData?.map((item, i) => (
                      <div
                        onClick={() => {
                          setFocus(true);
                          setDetailPageData(item);
                        }}
                        key={i}
                        className="nav-item cursor-pointer my-2 "
                      >
                        <div className="nav-profile-textt">
                          <h1>{getInitials(item?.name)}</h1>
                        </div>
                        <div className="nav-profile-text">
                          <span className="font-weight-bold mb-2">
                            <Trans>{item?.name}</Trans>
                          </span>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>
                      {watch("mobile")?.length < 10 ||
                      watch("mobile") == undefined
                        ? "Enter 10 digit mobile number to find users"
                        : "No Registered Users found with this number"}
                    </p>
                  )}
                </div>
              ) : null}
            </Form.Group>
          </Col> */}

          <Col xs={12} sm={6} md={4}>
            <Form.Group className='mb-3'>
              <Form.Label>Enter Email </Form.Label>

              <RHFTextField
                sx={inputStyles}
                fullWidth
                size='small'
                type='email'
                placeholder='Donor Email ID'
                name='email'
              />
            </Form.Group>
          </Col>

          <Col xs={12} sm={6} md={4}>
            <Form.Group className='mb-3'>
              <Form.Label>
                Date of Birth
                {/* <span className="text-danger">*</span> */}
              </Form.Label>

              <FormControl fullWidth>
                <RHFDatePicker
                  name='dob'
                  sx={inputStyles}
                  format='DD/MM/YYYY'
                  minDate={dayjs().subtract(65, 'years')}
                  maxDate={dayjs().subtract(18, 'years')}
                />
              </FormControl>
            </Form.Group>
          </Col>

          <Col xs={12} sm={6} md={4}>
            <Form.Group className='mb-3'>
              <Form.Label>
                Age <span className='text-danger'>*</span>
              </Form.Label>

              <FormControl
                // required
                fullWidth
              >
                <RHFTextField sx={inputStyles} fullWidth size='small' type='number' placeholder='Age' name='age' />
              </FormControl>
            </Form.Group>
          </Col>
          <Col xs={12} sm={6} md={4}>
            <Form.Group className="mb-3">
              <Form.Label>
                Gender <span className="text-danger">*</span>
              </Form.Label>

              <ul className="donate-now flex">
                <li>
                  <input
                    type="radio"
                    id="a25"
                    value="Male"
                    name="gender"
                    checked={watch("gender") === "Male"}
                    onChange={() => {
                      setValue("gender", "Male");
                    }}
                  />
                  <label htmlFor="a25">Male</label>
                </li>
                <li>
                  <input
                    type="radio"
                    id="a50"
                    value="Female"
                    name="gender"
                    checked={watch("gender") === "Female"}
                    onChange={() => {
                      setValue("gender", "Female");
                    }}
                  />
                  <label htmlFor="a50">Female</label>
                </li>
              </ul>
              {watch("gender") === "Other" ? (
                <FormHelperText sx={{ color: "error.main" }}>
                  Donor Ineligible for Donation
                </FormHelperText>
              ) : null}
            </Form.Group>
          </Col>

          <Col xs={12} sm={6} md={4}>
            <Form.Group className='mb-3'>
              <Form.Label>Martial Status </Form.Label>

              <MUIAutocomplete
                fullWidth
                options={[{ name: 'Married' }, { name: 'Single' }, { name: 'Prefer not to Say' }]}
                placeholder='Select martial status'
                value={watch('martial_status')}
                filterOptions={filterOptions}
                onChange={val => {
                  setValue('martial_status', val)
                }}
              />
            </Form.Group>
          </Col>

          {watch('martial_status') === 'Married' ? (
            <Col xs={12} sm={6} md={4}>
              <Form.Group className='mb-3'>
                <Form.Label>Enter Spouse Name</Form.Label>

                <RHFTextField
                  sx={inputStyles}
                  size='small'
                  fullWidth
                  type='text'
                  placeholder='Donor Spouse Name'
                  name='spouse_name'
                />
              </Form.Group>
            </Col>
          ) : null}

          {/* <section className="detail-section mt-4">
          <div className="detail-header">Contact Information</div>
          <div className="detail-grid">
            {contactInfo.map((item, idx) => (
              <div className="grid-item">
                {item["name"] !== "country" &&
                  item["name"] !== "pincode" &&
                  item["name"] !== "district" &&
                  item["name"] !== "state" && (
                    <>
                      <p className="item-header">{item["itemKey"]}</p>
                      <TextField
                        sx={inputStylesSmall}
                        fullWidth
                        onChange={handleBasicInfo}
                        type="text"
                        size="small"
                        value={basicInfoState[item?.name]}
                        // className="form-control abha_input-num"
                        placeholder={`Enter ${item["itemKey"]}`}
                        name={item["name"]}
                        //
                      />
                    </>
                  )}
                {item["name"] === "state" && (
                  <>
                    <p className="item-header">{item["itemKey"]}</p>
                    <Select
                      sx={inputSelectStyles}
                      fullWidth
                      type="text"
                      size="small"
                      placeholder="Enter State"
                      name="state"
                      onChange={(e) => {
                        handleBasicInfo(e);
                      }}
                      value={basicInfoState?.state}
                      error={Boolean(formErrors?.state)}
                      // MenuProps={{ classes: { paper: classes.menuPaper } }}
                      MenuProps={{ style: { maxHeight: 400 } }}
                    >
                      {states?.length > 0 ? (
                        states?.map((stat, i) => (
                          <MenuItem key={i} value={stat?.name}>
                            {stat?.name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem value="">No States found</MenuItem>
                      )}
                    </Select>
                  </>
                )}
                {item["name"] === "district" && (
                  <>
                    <p className="item-header">{item["itemKey"]}</p>
                    <Select
                      sx={inputSelectStyles}
                      fullWidth
                      type="text"
                      placeholder="Enter District"
                      name="district"
                      onChange={handleBasicInfo}
                      value={basicInfoState["district"]}
                      MenuProps={{ style: { maxHeight: 400 } }}
                      // required
                      error={Boolean(formErrors?.district)}
                      size="small"
                    >
                      {districts?.length > 0 ? (
                        districts?.map((stat, i) => (
                          <MenuItem key={i} value={stat?.name}>
                            {stat?.name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem value="">No District found</MenuItem>
                      )}
                    </Select>
                  </>
                )}

                {item["name"] === "country" && (
                  <>
                    <p className="item-header">{item["itemKey"]}</p>
                    <TextField
                      sx={inputStylesSmall}
                      fullWidth
                      //   onChange={handleBasicInfo}
                      type="text"
                      value={"India"}
                      // className="form-control abha_input-num"
                      placeholder={`Enter ${item["itemKey"]}`}
                      name={item["country"]}
                      disabled
                      size="small"
                    />
                  </>
                )}
                {item["name"] === "pincode" && (
                  <>
                    <p className="item-header">{item["itemKey"]}</p>
                    <TextField
                      sx={inputStylesSmall}
                      fullWidth
                      onChange={handleBasicInfo}
                      type="text"
                      value={basicInfoState[item?.name]}
                      // className="form-control abha_input-num"
                      placeholder={`Enter ${item["itemKey"]}`}
                      name={item["name"]}
                      size="small"
                    />
                  </>
                )}
                {formErrors[item["name"]] && (
                  <FormHelperText sx={{ color: "error.main" }}>
                    {formErrors[item["name"]]}
                  </FormHelperText>
                )}
              </div>
            ))}
          </div>
        </section> */}

          <Col md={12} className='Donor-details-sections'>
            <div className='detail-header'>Contact Information</div>
          </Col>

          <Col xs={12} sm={6} md={4}>
            <Form.Group className='mb-3'>
              <Form.Label>Address</Form.Label>

              <RHFTextField
                sx={inputStyles}
                fullWidth
                size='small'
                type='text'
                placeholder='Enter Address Line 1'
                name='area'
              />
            </Form.Group>
          </Col>

          <Col xs={12} sm={6} md={4}>
            <Form.Group className='mb-3'>
              <Form.Label>
                State <span className='text-danger'>*</span>
              </Form.Label>

              <MUIAutocomplete
                fullWidth
                options={states}
                placeholder='Select State'
                value={watch('state')}
                filterOptions={filterOptions}
                onChange={val => {
                  setValue('state', val, {
                    shouldValidate: true,
                    shouldDirty: true
                  })
                }}
                error={errors?.state?.message}
              />
            </Form.Group>
          </Col>

          <Col xs={12} sm={6} md={4}>
            <Form.Group className='mb-3'>
              <Form.Label>
                District <span className='text-danger'>*</span>
              </Form.Label>

              <MUIAutocomplete
                // sx={inputStyles}
                fullWidth
                options={districts}
                placeholder='Select District'
                value={watch('district')}
                filterOptions={filterOptions}
                onChange={val => {
                  setValue('district', val, {
                    shouldValidate: true,
                    shouldDirty: true
                  })
                }}
                error={errors?.district?.message}
              />
            </Form.Group>
          </Col>
          <Col xs={12} sm={6} md={4}>
            <Form.Group className='mb-3'>
              <Form.Label>
                City/Village<span className='text-danger'>*</span>
              </Form.Label>

              <RHFTextField sx={inputStyles} fullWidth type='text' size='small' placeholder='Enter City' name='city' />
            </Form.Group>
          </Col>
          <Col xs={12} sm={6} md={4}>
            <Form.Group className='mb-3'>
              <Form.Label>Pin Code </Form.Label>

              <RHFTextField
                sx={inputStyles}
                fullWidth
                type='number'
                size='small'
                placeholder='Enter Pin Code'
                name='pincode'
              />
            </Form.Group>
          </Col>
          <Col md={12}>
            <Form.Group className='mb-3'>
              {error && (
                <Alert severity='error' onClose={() => setError(false)} dismissible>
                  {message}{' '}
                </Alert>
              )}
            </Form.Group>
          </Col>
          <Col xs={12}>
            <div className='btn-container d-flex mt-2 '>
              <button onClick={handleEdit} type='button' className='btn backBtn '>
                Cancel
              </button>

              <button type='submit' className='btn gradient-btn '>
                Save
              </button>
            </div>
          </Col>
        </Row>
      </FormProvider>
    </div>
  )
}

export default EditProfile
