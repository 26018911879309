import React, { createElement, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import ToolTips from '../common/ToolTips'
import { checkPermission } from '../middleware/auth'
import { FiltersDonor } from './components/FiltersDonor'
import NoDataFound from '../common/NoDataFound'
import moment from 'moment'
import AxiosHelper from '../../helper/AxiosHelper'
import { censorDigits } from '../../helper/Validator'
import ScrollBar from 'react-perfect-scrollbar'
import ModalCustom2 from '../shared/ModalCustom2'
import { FormLabel, Toast } from 'react-bootstrap'
import FormProvider from '../shared/FormProvider'
import { FormHelperText, Grid, Menu, MenuItem, TextField } from '@mui/material'
import { inputStyles, labelStyles } from '../certification/EditCertificate'
import RHFTextField from '../shared/RHFTextfield'
import { toast } from 'react-toastify'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { useAppContext } from '../../AppContext'
import ReactTable, { SortingImg } from '../shared/ReactTable'
import { createColumnHelper } from '@tanstack/react-table'
const AllDonorsTable = ({ data, setcurrentFilter, currentFilter, getDonors, sorting, setSorting, isFiltered }) => {
  const [moreOptions, setMoreOptions] = useState(false)
  const [filstatus, setfilstatus] = useState(currentFilter)
  const [isLoading, setLoading] = useState(false)
  const [dilague, setDilague] = useState(false)
  const [delReason, setDelReason] = useState('')
  const [deleteData, setDeleteData] = useState({})
  const [delError, setDelError] = useState('')
  const [notiEl, setNotiEl] = useState(null)
  const [userEl, setUserEl] = useState(null)
  const history = useHistory()
  const { setDonor } = useAppContext()

  useEffect(() => {
    setfilstatus(currentFilter)
  }, [currentFilter])

  const handleClickDonor = async item => {
    localStorage.setItem('donor', JSON.stringify(item))
    setDonor(item?.name)
  }

  const getfilter = filterStatus => {
    setcurrentFilter(filterStatus)
  }

  const handleDropdownClose = () => {
    setNotiEl(null)
  }
  const downloadCertificate = id => {
    const toastId = toast.loading("Downloading file, please wait")
    AxiosHelper.post('certificate/donor-certifictate', {
      abha_id: id
    })
      .then(response => {
        const link = document.createElement('a')
        link.href = response?.data?.data
        link.click()
        link.remove()
        window.open(response?.data?.data, '_blank', 'rel=noopener noreferrer')
        toast.update(toastId, { render: "Your file has been successfully downloaded", type: "success", isLoading: false, autoClose: 2000, });
      })
      .catch(error => {
        console.log(error)
        toast.update(toastId, { render: "File download failed. Please try again later.", type: "error", isLoading: false, autoClose: 2000, });
      })
  }

  const handleDeleteRequest = (url, bodydata) => {
    setLoading(true)
    console.log(url)
    console.log(bodydata)
    AxiosHelper.put(url, bodydata)
      .then(response => {
        toast.success('Donor Details Successfully Deleted!')
        closeDialog()
        getDonors(1)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
        toast.error(error || 'Fail To Delete The Request!')
        console.log(error)
      })
  }

  const handleDelete = obj => {
    setDilague(true)
    setDelReason('')
    setDelError('')
  }

  const closeDialog = () => {
    setDeleteData({})
    setDelError('')
    setDelReason('')
    setDeleteData({})
    setDilague(false)
  }
  const confirmDialog = () => {
    if (delReason?.length > 0) {
      const bodyData = {
        id: deleteData?.id,
        reason: delReason
      }
      setDilague(false)
      handleDeleteRequest(`donor/delete`, bodyData)
    } else {
      setDelError('Delete Reason is required')
    }
  }

  const handleNotiDropdownOpen = event => {
    setNotiEl(event.currentTarget)
  }

  const handleUserDropdownOpen = event => {
    setUserEl(event.currentTarget)
  }

  const tableColumns = React.useMemo(() => {
    const tableColumnHelper = createColumnHelper()

    const columns = [
      tableColumnHelper.accessor('orderByName', {
        cell: ({ cell }) => (
          <div>
            <div
              className='donor-name cursor-pointer'
              onClick={() => {
                history.push(`/donors-detail/${cell?.row.original?.id}`)
                handleClickDonor(cell?.row.original)
              }}
            >
              {cell?.row.original?.name}
            </div>
            <div className='mt-2'>
              <>
                {cell?.row.original.age ? (
                  <> {cell?.row.original.age} Yr </>
                ) : (
                  <> {moment().diff(moment(cell?.row.original?.dob, 'YYYY-MM-DD'), 'years')} Yr </>
                )}
                | {cell?.row.original?.gender || '-'}
              </>
              <span className='hover-image'>
                <img
                  src={'/assets/images/Patient Info-1.svg'}
                  style={{
                    width: '20px',
                    height: '20px',
                    position: 'relative',
                    bottom: '3px'
                  }}
                  className='hover-image'
                  alt='info'
                />
                <div className='info'>
                  <div>
                    <span className='info-label'>Donor-ID :</span> {cell?.row.original?.reg_id || '-'}
                  </div>
                  <div className='mt-2'>
                    <span className='info-label'>Donation Date :</span>{cell?.row.original?.donation_date ? moment(cell?.row.original?.donation_date).format('DD-MMM-YYYY') : '-'}
                  </div>
                  <div className='mt-2'>
                    <span className='info-label'>Aadhaar :</span> {censorDigits(cell?.row.original?.adharcard) || '-'}
                  </div>
                  <div className='mt-2'>
                    <span className='info-label'>Donor Site :</span>   {cell?.row.original?.is_camp === 0 ? 'In-house' : 'In-camp'}
                  </div>
                  <div className='mt-2'>
                    <span className='info-label'>Donor Type :</span>    {cell?.row.original?.donation_type || '-'}
                  </div>
                </div>
              </span>
            </div>
          </div>
        ),
        header: info =>
          <div onClick={info.header.column.getToggleSortingHandler()}>
            Donor Name
            <SortingImg column={info.header.column} />
          </div>
      }),
      // tableColumnHelper.accessor('orderById', {
      //   cell: ({ cell }) => (
      //     <div>
      //       {cell?.row.original?.reg_id || '-'}
      //     </div>
      //   ),
      //   header: info =>
      //     <div onClick={info.header.column.getToggleSortingHandler()}>
      //       Donor-ID
      //       <SortingImg column={info.header.column} />
      //     </div>
      // }),
      // tableColumnHelper.accessor('orderByDonationDate', {
      //   cell: ({ cell }) => (
      //     <div>
      //       {cell?.row.original?.donation_date ? moment(cell?.row.original?.donation_date).format('DD-MMM-YYYY') : '-'}
      //     </div>
      //   ),
      //   header: info =>
      //     <div onClick={info.header.column.getToggleSortingHandler()}>
      //       Donation Date
      //       <SortingImg column={info.header.column} />
      //     </div>
      // }),
      tableColumnHelper.accessor('orderByBagNumber', {
        cell: ({ cell }) => (
          <div>
            {cell?.row.original?.bag_number || '-'}
          </div>
        ),
        header: info =>
          <div onClick={info.header.column.getToggleSortingHandler()}>
            Blood Bag Number
            <SortingImg column={info.header.column} />
          </div>
      }),
      // tableColumnHelper.accessor('is_camp', {
      //   cell: ({ cell }) => (
      //     <div>
      //       {cell?.row.original?.is_camp === 0 ? 'In-house' : 'In-camp'}
      //     </div>
      //   ),
      //   header: info => <div>Site</div>
      // }),
      // tableColumnHelper.accessor('orderByAdhar', {
      //   cell: ({ cell }) => (
      //     <div>
      //       {censorDigits(cell?.row.original?.adharcard) || '-'}
      //     </div>
      //   ),
      //   header: info =>
      //     <div onClick={info.header.column.getToggleSortingHandler()}>
      //       Aadhaar ID
      //       <SortingImg column={info.header.column} />
      //     </div>
      // }),
      // tableColumnHelper.accessor('orderByDonationType', {
      //   cell: ({ cell }) => (
      //     <div>
      //       {cell?.row.original?.donation_type || '-'}
      //     </div>
      //   ),
      //   header: info =>
      //     <div onClick={info.header.column.getToggleSortingHandler()}>
      //       Donation Type
      //       <SortingImg column={info.header.column} />
      //     </div>
      // }),
      tableColumnHelper.accessor('orderByMobile', {
        cell: ({ cell }) => (
          <div>
            {cell?.row.original?.mobile || '-'}
          </div>
        ),
        header: info =>
          <div onClick={info.header.column.getToggleSortingHandler()}>
            Mobile
            <SortingImg column={info.header.column} />
          </div>
      }),
      tableColumnHelper.accessor('steps', {
        cell: ({ cell }) => (
          <div>
            {cell?.row.original?.steps == '1' || cell?.row.original?.steps == '0' ? (
              <>
                {' '}
                {cell?.row.original?.defer_type === '2' ? <span className='donor-defered-permanent'>Permanent</span> : '-'}
              </>
            ) : (
              <>
                {cell?.row.original?.defer_type === null ? (
                  <span className='donor-fit'>Fit</span>
                ) : (
                  <span>
                    {cell?.row.original?.defer_type === '1' ? (
                      <span className='donor-defered-partial'>Partial</span>
                    ) : (
                      <span className='donor-defered-permanent'>Permanent</span>
                    )}
                  </span>
                )}
              </>
            )}
          </div>
        ),
        header: info => <div>Donor Status</div>
      }),
      tableColumnHelper.accessor('reason', {
        cell: ({ cell }) => (
          <div>
            {cell?.row.original?.reason || '-'}
          </div>
        ),
        header: info => <div>Message</div>
      }),
      tableColumnHelper.accessor('type', {
        cell: ({ cell }) => (
          <div>
            <span className='donor_action'>
              <ToolTips title='View Donor Profile' id='t-1' placement='top'>
                <img
                  onClick={e => {
                    history.push(`/donors-detail/${cell?.row.original.id}`)
                    handleClickDonor(cell?.row.original)
                  }}
                  src={'/assets/images/alldonors/view_donor.svg'}
                  alt='View Donor'
                />
              </ToolTips>

              {checkPermission('CREATE_SCREENING') && (
                <ToolTips title='Medical Screening' id='t-1' placement='top'>
                  <img
                    onClick={e => {
                      history.push(`/donors-detail/screening/${cell?.row.original.id}`)
                      handleClickDonor(cell?.row.original)
                    }}
                    src={'/assets/images/alldonors/stethoscope.svg'}
                    alt='Screening Process'
                  />
                </ToolTips>
              )}

              {checkPermission('CREATE_BLOOD_BAG') && (
                <ToolTips title='Assign Blood Bag' id='t-1' placement='top'>
                  <img
                    onClick={e => {
                      history.push(`/donors-detail/blood-inventory/${cell?.row.original.id}`)
                      handleClickDonor(cell?.row.original)
                    }}
                    src={'/assets/images/alldonors/blood_bag.svg'}
                    alt='Assign Blood Bag'
                  />
                </ToolTips>
              )}
              {checkPermission('UPDATE_TTI_TESTING') && (
                <ToolTips title='TTI Testing' id='t-1' placement='top'>
                  <img
                    onClick={e => {
                      history.push(`/donors-detail/testing/${cell?.row.original.id}`)
                      handleClickDonor(cell?.row.original)
                    }}
                    src={'/assets/images/alldonors/blood.svg'}
                    alt='TTI Testing'
                  />
                </ToolTips>
              )}

              {checkPermission('CREATE_BLOODS') && (
                <ToolTips title='Blood Component Inventory' id='t-1' placement='top'>
                  <img
                    onClick={e => {
                      history.push(`/donors-detail/donor-inventory/${cell?.row.original.id}`)
                      handleClickDonor(cell?.row.original)
                    }}
                    src={'/assets/images/alldonors/inventory.svg'}
                    alt='Blood Bag'
                  />
                </ToolTips>
              )}
              {/* {checkPermission('GENERATE_CERTIFICATE') === true && cell?.row.original?.donation_type === 'Voluntary' ? (
                <ToolTips title='Certification' id='t-1' placement='top'>
                  {cell?.row.original?.certificateDeliverLogEntity === null && cell?.row.original?.bag_number ? (
                    <img
                      style={{ maxWidth: '40px' }}
                      onClick={() => {
                        downloadCertificate(cell?.row.original.id)
                      }}
                      src={'/assets/images/alldonors/select.svg'}
                      alt='certificate'
                    />
                  ) : (
                    <img
                      style={{ maxWidth: '40px' }}
                      onClick={() => {
                        downloadCertificate(cell?.row.original.id)
                      }}
                      src={'/assets/images/certificate/certificate.png'}
                      alt='certificate'
                    />
                  )}
                </ToolTips>
              ) : null} */}

              {checkPermission('CREATE_BLOOD_BAG') && (
                <>
                  <ToolTips title='Delete' id='t-1' placement='top'>
                    <img
                      src={'/assets/images/deleteIcon.svg'}
                      alt='more-menu'
                      onClick={e => {
                        handleNotiDropdownOpen(e)
                        setDeleteData(cell?.row.original)
                        handleDelete(cell?.row.original)
                      }}
                    />
                  </ToolTips>
                </>
              )}
            </span>
          </div>
        ),
        header: info => <div>Action</div>
      }),
    ]

    return columns
  }, [])
  return (
    <>
      <ModalCustom2
        title='Are You Sure To Delete?'
        open={dilague}
        confirmDialog={confirmDialog}
        closeDialog={closeDialog}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div>
              <p>You are about to delete donor details</p>
            </div>
            <div className='table-responsive' style={{ boxShadow: '5px 5px 24px 0px rgba(63, 81, 181, 0.10)' }}>
              <table className='table  table-hover'>
                <tbody>
                  <tr>
                    <td>Donor-ID</td>
                    <td>{deleteData?.reg_id || '-'}</td>
                  </tr>
                  <tr>
                    <td>Name</td>
                    <td className='text-capitalize'>{deleteData?.name || '-'}</td>
                  </tr>
                  <tr>
                    <td>Mobile</td>
                    <td>{deleteData?.mobile || '-'}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Grid>

          <Grid item xs={12}>
            <FormLabel sx={labelStyles}>
              Reason for delete donor details<span className='text-danger'>*</span>
            </FormLabel>
            <TextField
              name='bag_number'
              size='small'
              fullWidth
              value={delReason}
              sx={inputStyles}
              onChange={e => {
                setDelReason(e.target.value)
                setDelError('')
              }}
            // InputProps={InputEndProps}
            />
            {delError ? (
              <FormHelperText
                style={{
                  color: 'red',
                  fontSize: '12px',
                  fontWeight: 'normal',
                  marginLeft: '0px'
                }}
              >
                {delError}
              </FormHelperText>
            ) : null}
          </Grid>
        </Grid>
      </ModalCustom2>
      <div className='filters-container-div   hide_scroll'>
        <FiltersDonor
          icon={'/assets/images/all-donor-filters/stethoscope.svg'}
          bgColor='linear-gradient(134deg, #D8F4EF 0%, #F0F3F5 100%)'
          title={data?.pending_screening || '00'}
          imgbg='#95CDC5'
          subtitle='Pending Screening'
          onClick={e => getfilter(filstatus === 2 ? 0 : 2)}
          cla={filstatus === 2 ? 'filter-ative' : ''}
        />
        <FiltersDonor
          icon={'/assets/images/all-donor-filters/Tdef.svg'}
          bgColor='linear-gradient(134deg, #D8E3F6 0%, #F0F3F5 100%)'
          imgbg='#93AED7'
          title={data?.total_defered || '00'}
          subtitle='Total Deferred'
          onClick={e => getfilter(filstatus === 1 ? 0 : 1)}
          cla={filstatus === 1 ? 'filter-ative' : ''}
        />
        <FiltersDonor
          icon={'/assets/images/all-donor-filters/Frame.svg'}
          bgColor='linear-gradient(134deg, #EFDADA 0%, #F0F3F5 100%)'
          title={data?.pending_blood_bag || '00'}
          imgbg='#D39E9E'
          subtitle='Pending Assign Blood Bag'
          onClick={e => getfilter(filstatus === 3 ? 0 : 3)}
          cla={filstatus === 3 ? 'filter-ative' : ''}
        />
        <FiltersDonor
          icon={'/assets/images/all-donor-filters/Ttiblood.svg'}
          bgColor='linear-gradient(134deg, #EEE8D9 0%, #F0F3F5 100%)'
          title={data?.pending_tti_testing || '00'}
          imgbg='#D4BD86'
          subtitle="Pending TTI Testing's"
          onClick={e => getfilter(filstatus === 4 ? 0 : 4)}
          cla={filstatus === 4 ? 'filter-ative' : ''}
        />
        <FiltersDonor
          icon={'/assets/images/all-donor-filters/pendingblood.svg'}
          bgColor='linear-gradient(134deg, #D9E6EE 0%, #F0F3F5 100%)'
          title={data?.pending_blood_inventory || '00'}
          imgbg='#7FB9DC'
          subtitle='Pending Blood Inventory'
          onClick={e => getfilter(filstatus === 5 ? 0 : 5)}
          cla={filstatus === 5 ? 'filter-ative' : ''}
        />
        <FiltersDonor
          icon={'/assets/images/all-donor-filters/Alldoners.svg'}
          bgColor='linear-gradient(134deg, #EDD9EE 0%, #F0F3F5 100%)'
          title={data?.total_donors || '00'}
          imgbg='#D19ED3'
          subtitle='All Donors'
          onClick={e => getfilter(0)}
          cla={filstatus === 0 ? 'filter-ative' : ''}
        />
      </div>
      <ReactTable
        enableSorting
        data={data?.response?.length ? data?.response : []}
        columns={tableColumns}
        state={{ sorting }}
        manualSorting={true}
        onSortingChange={setSorting}
        heightupper={isFiltered ? 70 : 20}
      />
      {/* {data?.response?.length == 0 && (
        <div className='dnr-no-data-found'>
          <NoDataFound title='No Record found' />
        </div>
      )} */}
    </>
  )
}

export default AllDonorsTable
