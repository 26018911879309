import moment from 'moment'

export const checkSpecialString = inputObject => {
  console.log('inputObject', inputObject)
  const regex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/
  if (Object.keys(inputObject).length < 0) return false

  for (const item of Object.keys(inputObject)) {
    const value = inputObject[item]
    if (value == '') {
      return { err: true, message: `${item} should not be empty` }
    }

    if (regex.test(value)) {
      return { err: true, message: `${item} should not contain special character` }
    }
  }
  return false
}

export const censorDigits = n => {
  if (!n) {
    return null
  }
  const stringDigits = String(n)
  let trimmingSize = 4

  if (stringDigits.length <= 4) {
    trimmingSize = Math.floor(stringDigits.length / 2)
  }

  const remaining = stringDigits.length - trimmingSize

  let first = stringDigits.substring(0, remaining)
  const last = stringDigits.substring(remaining, stringDigits.length)

  first = first
    .split('')
    .map(item => (item = '*'))
    .join('')

  const res = first + last

  if (res === '0') {
    return null
  }

  return res
}

export const calTimeLine = (trailData, basicData) => {
  let Timeline = {}
  //Donation
  if (basicData?.donation_date) {
    let campName = ''
    trailData?.PatientDonorEntity?.DonorCampEntities.forEach(item => {
      campName += item?.CampEntity?.camp_name
    })
    // console.log(campName);
    const donation = {
      date: basicData?.donation_date,
      label: `Appeared for donation`,
      label1: `${basicData?.name.split(' ')[0] || 'N/A'} appeared for blood donation ${campName ? `${campName} Camp` : `in-house`} .`
    }
    Timeline = { ...Timeline, donation }
  }
  //screening
  if (trailData?.screening_date) {
    const screening = {
      date: trailData?.screening_date,
      label: `Medical Screening`,
      label1: `HB was done by technician and phlebotomy was done by ${trailData?.BloodBagIssueEntity?.phlebotomist || '-'}  under the observation of ${trailData?.counseller || 'N/A'}`
    }
    Timeline = { ...Timeline, screening }
  }
  //donated blood
  if (basicData?.donation_date) {
    const donatedBlood = {
      date: basicData?.donation_date,
      label: `Donated Blood`,
      label1: `${basicData?.name || 'N/A'} donated blood duration ${trailData?.BloodBagIssueEntity?.bleeding_duration} minutes `
    }
    Timeline = { ...Timeline, donatedBlood }
  }
  //components
  if (trailData?.BloodBagIssueEntity?.BloodLogsEntities.length > 0) {
    let comdate
    let comp = ''
    trailData?.BloodBagIssueEntity?.BloodLogsEntities.forEach((item, idx, arr) => {
      comdate = item.createdAt
      comp += item.component + `(${item.volume}ml)${idx === arr.length - 1 ? ' ' : ', '}`
    })
    const components = {
      date: comdate,
      label: `Components prepared`,
      label1: `${comp} were prepared by ${trailData?.BloodBagIssueEntity?.phlebotomist || '-'}`
    }
    Timeline = { ...Timeline, components }
  }

  //bloodgrouping
  if (trailData?.BloodBagIssueEntity?.BloodTestingEntity?.createdAt) {
    const bloodgrouping = {
      date: trailData?.BloodBagIssueEntity?.BloodTestingEntity?.createdAt
        ? moment(trailData?.BloodBagIssueEntity?.BloodTestingEntity?.createdAt).format('DD MMM YYYY hh:mm A')
        : '',
      label: `Blood grouping`,
      label1: `${basicData?.name.split(' ')[0] || 'N/A'}'s blood group is ${trailData?.BloodBagIssueEntity?.BloodTestingEntity?.blood_group}.`
    }
    Timeline = { ...Timeline, bloodgrouping }
  }
  //TTI
  if (trailData?.BloodBagIssueEntity?.BloodTestingEntity?.id) {
    const ttiscreening = {
      date: trailData?.BloodBagIssueEntity?.BloodTestingEntity?.createdAt,
      label: `TTI Screening`,
      label1: `${basicData?.name.split(' ')[0] || 'N/A'}'s TTI screening was ${trailData?.BloodBagIssueEntity?.BloodTestingEntity?.is_declined === false ? 'negative' : 'positive'}.`
    }
    Timeline = { ...Timeline, ttiscreening }
  }
  //Reports
  if (trailData?.BloodBagIssueEntity?.BloodTestingEntity?.id) {
    const reports = {
      date: trailData?.updatedAt ? moment(trailData?.updatedAt).format('DD MMM YYYY hh:mm A') : '',
      label: `Blood Reports (${trailData?.updatedAt ? moment(trailData?.updatedAt).format('DD MMM YYYY hh:mm A') : ''})`,
      label1: `Your Blood Report is now available`
    }
    Timeline = { ...Timeline, reports }
  }
  return Timeline
}

export const CROSSMATCH_METHODS = [
  {
    value: 'not_applicable',
    label: 'Not Applicable',
  },
  {
    value: 'saline',
    label: 'Saline',
  },
  {
    value: 'gel_card',
    label: 'Coombs at 37 °C',
  },
  {
    value: 'manual',
    label: 'Manual',
  },
];